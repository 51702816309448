import "../Styles/Menu.css";

import bckg from "../Images/order.svg";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
import icn1 from "../Images/customerProfile.svg";
import icn2 from "../Images/orderDetailsIconActive.svg";
import icn3 from "../Images/menuIconActive.svg";
import icn4 from "../Images/basketIconUnactive.svg";
import {
  RiArrowRightSFill,
  RiArrowLeftSFill,
  RiShoppingCartFill,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import AddToCart from "../Components/AddToCart";
import { ImInfo } from "react-icons/im";
import category from "../Images/category.png";
import { useEffect, useState, useRef } from "react";
import { BeatLoader } from "react-spinners";
import {
  payment_methods,
  categories,
  products,
  product,
  Get_Basket,
  Delete_Item,
  Clear_cart,
  Edit_Quantity,
  check_offer,
  create_order,
  cancel_direct,
  accept_order,
} from "../api";

import ErrorModal from "../Components/ErrorModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import RemoveItem from "../Components/RemoveItem";
import CleartCart from "../Components/CleartCart";
import FreeMeals from "../Components/FreeMeals";
import CashBack from "../Components/CashBack";
import { customerAddresses } from "../api";
import { AiOutlineSearch } from "react-icons/ai";
import { TiTick, TiArrowRight } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import Discount from "../Components/Discount";
import { search } from "../api";

function Menu(props) {
  var touchsupport =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const [modal, setModal] = useState(false);
  const [toggleCart, setToggleCart] = useState(false);

  const { t, i18n } = useTranslation();
  let history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [freeMealsModal, setFreeMealsModal] = useState(false);
  const [freeMeals, setFreeMeals] = useState([]);
  const [menuLoading, setMenuLoading] = useState(false);
  //const[paymentMethod,setPaymentMethod]=useState((localStorage.getItem("editPaymentMethod"))?localStorage.getItem("editPaymentMethod"):"cash")
  const [arMsg, setArMsg] = useState();
  const [cashBack, setCashBack] = useState(false);
  const [enMsg, setEnMsg] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const [errModal, setErrModal] = useState(false);
  const [errModal2, setErrModal2] = useState(false);
  const [mealId, setMealId] = useState();
  const [freeMealsDisc, setFreeMealsDisc] = useState(false);
  const [cashBackDisc, setCashBackDisc] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [phoneNum, setPhoneNum] = useState();
  const [ws, setWs] = useState(null);

  const [menuCategories, setMenuCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const [notesSearch, setNotesSearch] = useState("");
  const [noNotes, setNoNotes] = useState(false);
  const [quanLoading, setQuanLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [productData, setProductData] = useState();
  const [basket, setBasket] = useState(null);
  const [searchTerm, SetSearchTerm] = useState("");
  const [add, setAdd] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [discAmount, setDiscAmount] = useState();
  const [discTotal, setDiscTotal] = useState();
  const [delId, setDelId] = useState();
  const [noProduct, setNoProduct] = useState(false);
  const [payment, setPayment] = useState();
  const [laterPeriod, setLaterPeriod] = useState(false);
  const phoneRef = useRef();
  const [status, setStatus] = useState("disconnected");

  const [note, setNote] = useState("");
  const [paymentWalletTotal, setPaymentWalletTotal] = useState("");
  const [noteText, setNoteText] = useState(
    localStorage.getItem("notes")
      ? localStorage.getItem("notes")
      : localStorage.getItem("editNotes")
      ? localStorage.getItem("editNotes")
      : ""
  );
  const [address, setAddress] = useState({
    arAddress: "",
    enAddress: "",
  });
  const [tableDiscount, setTableDiscount] = useState(
    localStorage.getItem("tableDiscount")
      ? localStorage.getItem("tableDiscount")
      : ""
  );
  const [tableDiscountType, setTableDiscountType] = useState(
    localStorage.getItem("tableDiscountType")
      ? localStorage.getItem("tableDiscountType")
      : ""
  );

  const [tableAccount, setTableAccount] = useState(
    localStorage.getItem("tableAccount")
      ? localStorage.getItem("tableAccount")
      : ""
  );

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  const [selectedPaymentCont, setSelectedPaymentCont] = useState(
    localStorage.getItem("PaymentMethodDiscContd")
      ? localStorage.getItem("PaymentMethodDiscContd")
      : null
  );
  const [totalAfterWallet, setTotalAfterWallet] = useState();
  const [selectedPayment, setSelectedPayment] = useState(
    localStorage.getItem("PaymentMethodDisc")
      ? localStorage.getItem("PaymentMethodDisc")
      : localStorage.getItem("editPaymentMethod")
      ? localStorage.getItem("editPaymentMethod")
      : null
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cartChange, setCartChange] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [branchId, setBranchId] = useState(
    parseInt(localStorage.getItem("branchId"))
  );
  //const [notes,setNotes]= useState(localStorage.getItem("editNotes")?localStorage.getItem("editNotes"):" ")

  const codeRef = useRef();
  const [codeValue, setCodeValue] = useState(
    localStorage.getItem("promoCode") ? localStorage.getItem("promoCode") : null
  );
  const [disableButton, setDisableButton] = useState(true);
  const [walletLoad, setWalletLoad] = useState(true);
  const [showButton, setShowButton] = useState(false);

  var today = new Date();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  let day = today.getDate();
  let hour = today.getHours();
  let minute = today.getMinutes();
  let second = today.getSeconds();

  // useEffect(() => {
  //   // Disable scrolling
  //   document.body.style.overflow = 'hidden';

  //   // Cleanup function to re-enable scrolling
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };

  // }, []);

  var checkList;
  function dropDown() {
    checkList = document.getElementById("list1");
    if (checkList.classList.contains("visible")) {
      checkList.classList.remove("visible");
    } else {
      checkList.classList.add("visible");
    }
    /*
else{
  if(localStorage.getItem("editNotes")){

    var arr=localStorage.getItem("editNotes").split(",")
    var elements = document.getElementsByClassName('checkNotes');
   
    for(var i=0; i<elements.length; i++) { 
      for(var j=0; j<elements.length; j++) { 
      if(elements[i].value==arr[j]){
     
        elements[i].checked=true;
    }}
    }
  }

  checkList.classList.add('visible');
}


var checkArr=[]
var check = document.getElementsByClassName('checkNotes');
for(var i=0; i<check.length; i++) { 
  if(check[i].checked){
 
    checkArr.push(" "+check[i].value )

}
}
const notes= checkArr.toString()
setNote(notes)

*/

    if (localStorage.getItem("notesCheckbox")) {
      console.log("test");
      var arr = localStorage.getItem("notesCheckbox").split(",");
      var elements = document.getElementsByClassName("checkNotes");
      for (var i = 0; i < elements.length; i++) {
        for (var j = 0; j < elements.length; j++) {
          if (elements[i].value == arr[j]) {
            elements[i].checked = true;
          }
        }
      }
    }

    var checkArr = [];
    var check = document.getElementsByClassName("checkNotes");
    for (var i = 0; i < check.length; i++) {
      if (check[i].checked) {
        checkArr.push(" " + check[i].value);
      }
    }
    const notes = checkArr.toString();
    setNote(notes);
  }

  function handlePhone(e) {
    let newStr = e.target.value;
    let arr = [];
    for (let i = 0; i < newStr.length; i++) {
      if (!isNaN(newStr[i]) && newStr[i] !== " ") {
        arr.push(newStr[i]);
      }
    }

    setPhoneNum(arr.join(""));
    console.log(arr.join(""));

    console.log(arr.join("") == "");

    if (arr.join("") == "") {
    } else {
    }

    if (!arr.join("").match(phoneRegEx) && !arr.join("").toString() == "") {
    } else if (
      arr.join("").match(phoneRegEx) &&
      arr.join("").toString() == ""
    ) {
    }
  }
  function handleSearchCustomer() {
    if (phoneNum == null) {
      return;
    } else if (phoneNum !== null) {
      setLoading(true);
      const token = localStorage.getItem("token");

      let data = {
        phone: phoneRef.current.value,
      };

      search(token, data)
        .then((response) => {
          if (response.data.message === "success") {
            //  this.clearBasket();
            if (response.data.data.length > 0) {
              localStorage.setItem("customerId", response.data.data[0].id);
              localStorage.setItem("customerName", response.data.data[0].name);
              localStorage.setItem(
                "customerNotes",
                response.data.data[0].notes
              );
              localStorage.setItem(
                "customerPhone",
                response.data.data[0].phone
              );
              localStorage.setItem(
                "customerPhoto",
                response.data.data[0].photo
              );
              localStorage.setItem(
                "customerAwafi",
                response.data.data[0].joinLoyalty
              );
              localStorage.setItem(
                "accountType",
                response.data.data[0].profileType == "Company User"
                  ? true
                  : false
              );
              localStorage.setItem(
                "organizationId",
                response.data.data[0].userOrganizationId
              );
              setLoading(true);

              /*this.setState({
        customerFoundRedirect:true,
        customerPhone: response.data.data[0].phone,

      }); */
            }
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            setLoading(false);
          } else if (error.response.status === 403) {
            props.setErr(true);
            props.setMsg(t("validation.noPermissionAction"));
            props.setStyle({ top: "50%" });

            setTimeout(function () {
              props.setErr(false);
              setLoading(false);
            }, 2500);
          } else if (error.response.status === 401) {
            history("/");
          } else {
            setLoading(false);
          }
        });
    }
  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key

    if (e.key == "Enter") {
      handleSearchCustomer();
    }
  };

  function handleNotes(e) {
    var checkArr = [];
    var check = document.getElementsByClassName("checkNotes");
    for (var i = 0; i < check.length; i++) {
      if (check[i].checked) {
        checkArr.push("" + check[i].value);
      }
    }
    const notes = checkArr.toString();
    setNote(notes);
    localStorage.setItem("notesCheckbox", notes);

    if (localStorage.getItem("editNotes")) {
      localStorage.setItem("editNotes", notes);
    }
  }

  //if order type id is 2 (home delivery) customerAddresses api is called takes token and customer id to retrive customer addressses
  //address is set to the address with matching id (chosen in order details step)
  //Get_Basket api call takes token and customer id()=> fetch customer cart data
  //payment_methods api call takes token, cart id and delivery type (oridnary if order type id is 2 else " ")()=> fetch customer cart data and payment options
  //categories api call takes token ()=>fetch categories
  //getProducts function is called with inital category id as argument ()=> fetch products

  useEffect(() => {
    if (localStorage.getItem("type") === "cashier") {
      connectWebSocket();
      return () => {
        if (ws) {
          ws.close();
          console.log("WebSocket connection closed.");
        }
      };
    }
  }, []);

  useEffect(() => {
    /*
  if(localStorage.getItem("editNotes")){
    setNote(localStorage.getItem("editNotes"))
  }*/

    const token = localStorage.getItem("token");

    if (parseInt(localStorage.getItem("orderType")) === 2) {
      customerAddresses(token, localStorage.getItem("customerId"))
        .then((response) => {
          const found = response.data.data.find(
            (element) =>
              element.id == parseInt(localStorage.getItem("addressId"))
          );
          if (found) {
            setAddress({
              arAddress: found.arAddress,
              enAddress: found.enAddress,
            });
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            history("/");
          }
        });
    }

    /*
  if(parseInt(localStorage.getItem("orderSource")) === 40 || parseInt(localStorage.getItem("orderSource")) === 80 || parseInt(localStorage.getItem("orderSource")) === 85 || parseInt(localStorage.getItem("orderSource")) === 95){
    localStorage.setItem("paymentMethod","paid");
   // setPaymentMethod("paid")
   
}else if(!localStorage.getItem("paymentMethod")){
    localStorage.setItem("paymentMethod","cash");
}*/

    categories(token, parseInt(localStorage.getItem("orderType")))
      .then((response) => {
        setMenuCategories(response.data.data);

        if (categoryId == null) {
          setCategoryId(response.data.data[0].id);
          getProducts(response.data.data[0].id);
        } else {
          getProducts(categoryId);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }

        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);

        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });
  }, []);

  useEffect(() => {
    SetSearchTerm("");
    setNoProduct(false);

    const token = localStorage.getItem("token");

    Get_Basket(
      token,
      localStorage.getItem("customerId"),
      localStorage.getItem("orderSource"),
      localStorage.getItem("type")
    )
      .then((response) => {
        if (
          parseInt(response.data.data.cart.typeId) !==
          parseInt(localStorage.getItem("orderType"))
        ) {
          handleClear();
        }
        // check period
        getPeriod(
          response.data.data.cart.firstPeriodTimeFrom,
          response.data.data.cart.firstPeriodTimeTo,
          response.data.data.cart.secondPeriodTimeFrom,
          response.data.data.cart.secondPeriodTimeTo
        );

        if (localStorage.getItem("PaymentMethodDisc")) {
          if (localStorage.getItem("editPaymentMethod")) {
            localStorage.setItem(
              "editPaymentMethod",
              localStorage.getItem("PaymentMethodDisc")
            );
          }
        }

        setBasket(response.data.data);
        setBranchId(response.data.data.cart.branchId);
        localStorage.setItem("CartId", response.data.data.cart.id);

        payment_methods(
          response.data.data.cart.id,
          response.data.data.cart.typeId == 2 ? "ordinary" : " ",
          localStorage.getItem("orderSource"),
          token
        ).then((response) => {
          setPayment(response.data.data);
          //on edit order check if payment method is still ana vailable option
          if (localStorage.getItem("editPaymentMethod")) {
            const entries = Object.entries(response.data.data.paymentMethods);
            const key = (element) =>
              element > localStorage.getItem("editPaymentMethod");
            let indexOf = entries.findIndex(key);
            if (entries[indexOf][1] == false) {
              localStorage.removeItem("editPaymentMethod");
              localStorage.removeItem("PaymentMethodDisc");

              setSelectedPayment(null);
            }
          } else {
            if (localStorage.getItem("PaymentMethodDisc")) {
              const entries = Object.entries(response.data.data.paymentMethods);
              const key = (element) =>
                element > localStorage.getItem("PaymentMethodDisc");
              let indexOf = entries.findIndex(key);
              if (entries[indexOf][1] == false) {
                localStorage.removeItem("PaymentMethodDisc");

                setSelectedPayment(null);
              }
            }
          }

          if (
            response.data.data.paymentMethods.cash &&
            !response.data.data.paymentMethods.paid &&
            !response.data.data.paymentMethods.paymentOnline &&
            !response.data.data.paymentMethods.wallet
          ) {
            setSelectedPayment("cash");
          } else if (
            !response.data.data.paymentMethods.cash &&
            response.data.data.paymentMethods.paid &&
            !response.data.data.paymentMethods.paymentOnline &&
            !response.data.data.paymentMethods.wallet
          ) {
            setSelectedPayment("paid");
          } else if (
            !response.data.data.paymentMethods.cash &&
            !response.data.data.paymentMethods.paid &&
            response.data.data.paymentMethods.paymentOnline &&
            !response.data.data.paymentMethods.wallet
          ) {
            setSelectedPayment("tap");
          } else if (
            !response.data.data.paymentMethods.cash &&
            !response.data.data.paymentMethods.paid &&
            !response.data.data.paymentMethods.paymentOnline &&
            response.data.data.paymentMethods.wallet
          ) {
            setSelectedPayment("wallet");
            /* if(boolRemainder){
      calcWallet();
        }*/
          }
          if (discount) {
            handleOffer(false);
          }

          setLoading(false);

          setQuanLoading(false);
        });
      })
      .catch((err) => {
        setBasket(null);
        setLoading(false);
      });
  }, [add, cartChange]);

  useEffect(() => {
    if (localStorage.getItem("PaymentMethodDisc") && payment !== undefined) {
      if (localStorage.getItem("PaymentMethodDisc") == "wallet") {
        calcWallet();

        /* const boolRemainder=((localStorage.getItem("editPaymentMethod") && localStorage.getItem("editPaymentMethod")=="wallet")||(localStorage.getItem("PaymentMethodDisc") && localStorage.getItem("PaymentMethodDisc")=="wallet") || selectedPayment=="wallet") &&( discount? !(response.data.data.walletBalance>=discTotal):!(response.data.data.walletBalance>=response.data.data.totalPrice))

    if(boolRemainder && response.data.data.paymentMethods.wallet){
  calcWallet();
    }*/
      }
    }
  }, [payment, discTotal]);

  //function to check period
  function getPeriod(ff, ft, sf, st) {
    var firstPeriodTimeFrom = ff;
    var firstPeriodTimeTo = ft;
    var secondPeriodTimeFrom = sf;
    var secondPeriodTimeTo = st;

    var now = new Date(year, month, day, hour, minute, second);
    var firstOnept1 = new Date(
      year,
      month,
      day,
      firstPeriodTimeFrom.slice(0, 2),
      firstPeriodTimeFrom.slice(3, 5),
      firstPeriodTimeFrom.slice(6, 8)
    );
    var firstOnept2 = new Date(
      year,
      month,
      day,
      firstPeriodTimeTo.slice(0, 2),
      firstPeriodTimeTo.slice(3, 5),
      firstPeriodTimeTo.slice(6, 8)
    );
    var secondOnept1 = new Date(
      year,
      month,
      day,
      secondPeriodTimeFrom.slice(0, 2),
      secondPeriodTimeFrom.slice(3, 5),
      secondPeriodTimeFrom.slice(6, 8)
    );
    var secondOnept2 = new Date(
      year,
      month,
      day,
      secondPeriodTimeTo.slice(0, 2),
      secondPeriodTimeTo.slice(3, 5),
      secondPeriodTimeTo.slice(6, 8)
    );

    if (localStorage.getItem("now") == "false") {
      const date = localStorage.getItem("orderDate");
      const time = localStorage.getItem("orderTime");

      const laterDate = new Date(
        date.slice(0, 4),
        date.slice(5, 7),
        date.slice(8, 10),
        time.slice(0, 2),
        time.slice(3, 5),
        time.slice(6, 8)
      );

      if (firstOnept1 <= now && now <= firstOnept2) {
        if (!(firstOnept1 <= laterDate && firstOnept2 >= laterDate)) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      } else {
        console.log("second");
        if (!(secondOnept1 <= laterDate && secondOnept2 >= laterDate)) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      }
    }
  }

  //called on changing category
  //set active category
  /*products api call takes token, chosen category id, branch id and order source code
fetch products,*/
  const getProducts = (categoryId) => {
    localStorage.setItem("categoryId", categoryId);

    const token = localStorage.getItem("token");

    products(token, categoryId, branchId, localStorage.getItem("orderSource"))
      .then((response) => {
        setCategoryId(categoryId);
        setProductList(response.data.data);

        const period = response.data.period;

        var firstPeriodTimeFrom = period.firstPeriodTimeFrom;
        var firstPeriodTimeTo = period.firstPeriodTimeTo;
        var secondPeriodTimeFrom = period.secondPeriodTimeFrom;
        var secondPeriodTimeTo = period.secondPeriodTimeTo;

        var now = new Date(year, month, day, hour, minute, second);
        var firstOnept1 = new Date(
          year,
          month,
          day,
          firstPeriodTimeFrom.slice(0, 2),
          firstPeriodTimeFrom.slice(3, 5),
          firstPeriodTimeFrom.slice(6, 8)
        );
        var firstOnept2 = new Date(
          year,
          month,
          day,
          firstPeriodTimeTo.slice(0, 2),
          firstPeriodTimeTo.slice(3, 5),
          firstPeriodTimeTo.slice(6, 8)
        );
        var secondOnept1 = new Date(
          year,
          month,
          day,
          secondPeriodTimeFrom.slice(0, 2),
          secondPeriodTimeFrom.slice(3, 5),
          secondPeriodTimeFrom.slice(6, 8)
        );
        var secondOnept2 = new Date(
          year,
          month,
          day,
          secondPeriodTimeTo.slice(0, 2),
          secondPeriodTimeTo.slice(3, 5),
          secondPeriodTimeTo.slice(6, 8)
        );

        if (localStorage.getItem("now") == "false") {
          const date = localStorage.getItem("orderDate");
          const time = localStorage.getItem("orderTime");

          const laterDate = new Date(
            date.slice(0, 4),
            date.slice(5, 7),
            date.slice(8, 10),
            time.slice(0, 2),
            time.slice(3, 5),
            time.slice(6, 8)
          );

          if (firstOnept1 <= now && now <= firstOnept2) {
            if (!(firstOnept1 <= laterDate && firstOnept2 >= laterDate)) {
              setLaterPeriod(true);
            }
          } else {
            if (!(secondOnept1 <= laterDate && secondOnept2 >= laterDate)) {
              setLaterPeriod(true);
            }
          }
        } else {
          setLaterPeriod(false);
        }

        setMenuLoading(false);

        //(false)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        }

        setArMsg(error.response.data.error);
        setEnMsg(error.response.data.error);
        setErrModal(true);

        setMenuLoading(false);
        setLoading(false);
        setTimeout(function () {
          setErrModal(false);
        }, 2500);
      });
  };
  //to presist category
  useEffect(() => {
    setCategoryId(localStorage.getItem("categoryId"));
  }, [add, cartChange]);

  //called on choosing product
  //product api call takes token, chosen product id, branch id and order source code ()=> fetch product data
  const getProduct = (productId) => {
    const token = localStorage.getItem("token");

    product(
      token,
      productId,
      branchId,
      localStorage.getItem("orderSource"),
      parseInt(localStorage.getItem("customerId"))
    )
      .then((response) => {
        setProductData(response.data.data);

        setModal(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history("/");
        } else {
          setArMsg(error.response.data.error);
          setEnMsg(error.response.data.error);
          setErrModal(true);

          setTimeout(function () {
            setErrModal(false);
          }, 2500);
        }
      });
  };

  //on confirming clear in ClearCart component
  //Clear_cart api call takes cart id and token

  function handleClear() {
    setClearModal(false);
    setLoading(true);
    const token = window.localStorage.getItem("token");
    Clear_cart(localStorage.getItem("CartId"), token).then((response) => {
      setSelectedPayment(null);
      setDiscount(false);
      localStorage.setItem("discount", "false");

      setCodeValue("");
      setErrModal2(false);
      setCodeInvalid(false);
      localStorage.setItem("discount", "false");
      localStorage.removeItem("offer");

      localStorage.removeItem("notes");

      localStorage.removeItem("tableDiscount");

      localStorage.removeItem("tableDiscountType");
      localStorage.removeItem("tableAccount");
      localStorage.removeItem("customerPhone");

      localStorage.removeItem("PaymentMethodDisc");
      localStorage.removeItem("PaymentMethodDiscContd");
      localStorage.removeItem("promoCode");
      setCartChange(!cartChange); //called to rerender useEffect
      setNote("");
      setNoteText("");
      localStorage.removeItem("notesCheckbox");

      localStorage.removeItem("editNotes");
      localStorage.removeItem("cancelOrderId");
      localStorage.removeItem("editPaymentMethod");

      setLoading(false);
    });
  }
  //on confirming clear in RemoveItem component
  //Delete_Item api call takes cart id and meal id (set when clicking delete on a meal) token
  function handleRemove() {
    setRemoveModal(false);
    setLoading(true);
    const token = window.localStorage.getItem("token");

    Delete_Item(localStorage.getItem("CartId"), delId, token).then(
      (response) => {
        localStorage.removeItem("offer");

        localStorage.setItem("promoCode", codeValue);
        setCartChange(!cartChange); //called to rerender useEffect

        setLoading(false);
      }
    );
  }

  //onChanging meal quantity with buttons
  //Edit_Quantity api call takes cart id, meal id, new quantity and token
  function handleQuantity(mealId, quantity) {
    const token = window.localStorage.getItem("token");
    setQuanLoading(true);
    Edit_Quantity(localStorage.getItem("CartId"), mealId, quantity, token).then(
      (response) => {
        setCartChange(!cartChange); //called to rerender useEffect
      }
    );
  }

  //onChanging meal quantity
  //Edit_Quantity api call takes cart id, meal id, new quantity and token
  function handleChangeQuantity(
    mealId,
    quantity,
    oldQuantity,
    avalilableQuantity
  ) {
    setQuanLoading(true);
    const token = window.localStorage.getItem("token");
    setMealId(mealId);

    if (quantity < 1) {
      document.getElementById(`${mealId}`).value = oldQuantity;
      setQuanLoading(false);
    } else {
      if (quantity > avalilableQuantity && disableButton) {
        document.getElementById(`${mealId}`).value = avalilableQuantity;
        Edit_Quantity(
          localStorage.getItem("CartId"),
          mealId,
          avalilableQuantity,
          token
        ).then((response) => {
          localStorage.removeItem("offer");

          setCartChange(!cartChange); //called to rerender useEffect
        });
      } else {
        document.getElementById(`${mealId}`).value = quantity;

        Edit_Quantity(
          localStorage.getItem("CartId"),
          mealId,
          quantity,
          token
        ).then((response) => {
          localStorage.removeItem("offer");

          setCartChange(!cartChange); //called to rerender useEffect
        });
      }
    }
  }

  //check_offer api call takes (data) object
  //if there is a discount or free meals a modal is displayed
  function handleOffer(create) {
    console.log(create);

    if (create == true) {
      setShowButton(true);
      setSubmitLoading(true);
    } else {
      setShowButton(false);
    }
    setErrModal2(false);
    setCodeInvalid(false);

    if (selectedPayment == null || selectedPayment == " ") {
      setErrModal(true);
      setArMsg("اختر طريقة الدفع");
      setEnMsg("Choose payment method");
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
      setSubmitLoading(false);
    } else if ((codeValue == null || codeValue == "") && create == false) {
      console.log(create);

      setDiscount(false);
      setFreeMealsDisc(false);
      setCashBackDisc(false);
      localStorage.setItem("discount", "false");
      setErrModal2(true);
      setArMsg(" برجاء إدخال الرمز الترويجي  ");
      setEnMsg("please enter promo code");
      setSubmitLoading(false);
      if (selectedPayment == "wallet") {
        calcWallet();
      }
    } else {
      const token = window.localStorage.getItem("token");

      var arr = [];
      var elements = document.getElementsByClassName("checkNotes");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].checked) {
          arr.push(elements[i].value);
        }
      }

      arr.push(noteText);
      const notes = arr.toString();
      const promoCode = localStorage.getItem("promoCode");
      const discountCode = !discount ? codeRef.current?.value : promoCode;

      const data = {
        notes: notes,
        date:
          localStorage.getItem("now") == "true"
            ? ""
            : localStorage.getItem("orderDate"),
        now: localStorage.getItem("now"),
        time:
          localStorage.getItem("now") == "true"
            ? ""
            : localStorage.getItem("orderTime"),
        paymentMethod: selectedPayment,
        applicationId: 4,
        code: discountCode ? discountCode : "",
        userId:
          localStorage.getItem("customerId") != null
            ? parseInt(localStorage.getItem("customerId"))
            : parseInt(localStorage.getItem("userId")),
        accountType: localStorage.getItem("type"),
      };

      check_offer(data, token, localStorage.getItem("orderSource"))
        .then((response) => {
          setCodeInvalid(false);

          if (response.data.data.offerType === "freeMeals") {
            localStorage.setItem("offer", true);
            setFreeMeals(response.data.data.freeMeals);
            setFreeMealsModal(true);
            setFreeMealsDisc(true);
          } else if (response.data.data.offerType === "discount") {
            localStorage.setItem("offer", true);
            setDiscount(true);
            if (create == true) {
              setDiscountModal(true);
            }
            setDiscAmount(response.data.data.discount);
            setDiscTotal(response.data.data.totalPrice);
            localStorage.setItem("discount", "true");

            if (selectedPayment == "wallet") {
              calcWallet();
            }
          } else if (response.data.data.offerType === "cashBack") {
            localStorage.setItem("offer", true);
            setCashBack(true);
            setCashBackDisc(true);
          } else {
            setDiscount(false);
            setFreeMealsDisc(false);
            setCashBackDisc(false);
            localStorage.removeItem("offer");
            localStorage.setItem("discount", "false");
            setErrModal2(true);
            setArMsg("الرمز الترويجي غير صحيح");
            setEnMsg("Promo code is invalid");
            setSubmitLoading(false);
            if (selectedPayment == "wallet") {
              calcWallet();
            }
            if (create == true) {
              createOrder();
            }
          }
        })
        .catch((error) => {
          setDiscount(false);
          setFreeMealsDisc(false);
          setCashBackDisc(false);
          localStorage.setItem("discount", "false");

          if (error.response.status === 401) {
            history("/");
          } else if (error.response.data.errorType == "code") {
            setCodeInvalid(true);

            if (
              error.response.data.error ===
              "طلبك غير مستوفي لشروط الرمز الترويجي"
            ) {
              setEnMsg("طلبك غير مستوفي لشروط الرمز الترويجي");
              setArMsg("طلبك غير مستوفي لشروط الرمز الترويجي");
              setErrModal2(true);
              setSubmitLoading(false);
            } else if (
              error.response.data.error === "الرمز الترويجي غير صحيح"
            ) {
              setArMsg("الرمز الترويجي غير صحيح");
              setEnMsg("Promo code is invalid");
              setErrModal2(true);
              setSubmitLoading(false);
            } else if (
              error.response.data.error ===
              "الرمز الترويجي غير متاح في هذا الفرع"
            ) {
              setArMsg("الرمز الترويجي غير متاح في هذا الفرع");
              setEnMsg("Promo code is unavailable in this branch");
              setErrModal2(true);
              setSubmitLoading(false);
            }
          } else {
            getError(error.response.data.error);
          }
        });
    }
  }

  //on submitting order
  //create_order api call takes (data) object and token
  //user is then redirected to /order-success
  const createOrder = () => {
    const boolRemainder =
      ((localStorage.getItem("editPaymentMethod") &&
        localStorage.getItem("editPaymentMethod") == "wallet") ||
        (localStorage.getItem("PaymentMethodDisc") &&
          localStorage.getItem("PaymentMethodDisc") == "wallet") ||
        selectedPayment == "wallet") &&
      (discount
        ? !(payment.walletBalance >= discTotal)
        : !(payment.walletBalance >= payment.totalPrice));

    if (selectedPayment == null) {
      showError("اختر طريقة الدفع", "Choose payment method");
    } else if (
      boolRemainder &&
      selectedPaymentCont == null &&
      payment.paymentMethods.wallet
    ) {
      showError(
        "اختر طريقة استكمال الدفع",
        "Choose method to continue payment"
      );
    } else if (
      localStorage.getItem("tableDiscount") &&
      localStorage.getItem("tableDiscount") !== "" &&
      (localStorage.getItem("tableDiscountType") == null ||
        localStorage.getItem("tableDiscountType") == "")
    ) {
      showError("اختر نوع الخصم", "Choose discount type");
    } else if (
      localStorage.getItem("tableDiscount") &&
      localStorage.getItem("tableDiscount") !== "" &&
      (localStorage.getItem("tableAccount") == null ||
        localStorage.getItem("tableAccount") == "")
    ) {
      showError("اختر حساب الخصم", "Choose discount account");
    } else {
      // Proceed with order creation
      submitOrder();
    }
  };

  const showError = (arMsg, enMsg) => {
    setErrModal(true);
    setArMsg(arMsg);
    setEnMsg(enMsg);
    setTimeout(() => {
      setErrModal(false);
    }, 2000);
    setSubmitLoading(false);
  };

  const submitOrder = () => {
    const token = window.localStorage.getItem("token");
    setSubmitLoading(true);
    const boolRemainder =
      ((localStorage.getItem("editPaymentMethod") &&
        localStorage.getItem("editPaymentMethod") == "wallet") ||
        (localStorage.getItem("PaymentMethodDisc") &&
          localStorage.getItem("PaymentMethodDisc") == "wallet") ||
        selectedPayment == "wallet") &&
      (discount
        ? !(payment.walletBalance >= discTotal)
        : !(payment.walletBalance >= payment.totalPrice));

    var arr = [];
    var elements = document.getElementsByClassName("checkNotes");
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].checked) {
        arr.push(elements[i].value);
      }
    }

    arr.push(noteText);
    const notes = arr.toString();

    const data = {
      discountAccount: localStorage.getItem("tableDiscount")
        ? parseInt(localStorage.getItem("tableDiscount"))
        : "",
      discountType: localStorage.getItem("tableDiscountType")
        ? localStorage.getItem("tableDiscountType")
        : "",
      discountAmount: localStorage.getItem("tableAccount")
        ? parseInt(localStorage.getItem("tableAccount"))
        : "",
      paymentMethod:
        selectedPayment == "awafi"
          ? "expirable_wallet"
          : selectedPayment == "tap"
          ? payment.paymentMethods.paymentOnlineType
          : selectedPayment == "shabka"
          ? "intrasoft"
          : selectedPayment,
      remainPaymentMethod: boolRemainder ? selectedPaymentCont : "",
      sourceId: parseInt(localStorage.getItem("orderSource")),
      externalOrderId:
        parseInt(localStorage.getItem("orderSource")) == 5
          ? ""
          : parseInt(localStorage.getItem("externalOrderId")),
      code:
        localStorage.getItem("type") !== "cashier" ? codeRef.current.value : "",
      notes: notes,
      applicationId: 4,
      canceledOrderId: localStorage.getItem("cancelOrderId")
        ? parseInt(localStorage.getItem("cancelOrderId"))
        : "",
      userId:
        localStorage.getItem("customerId") != null
          ? parseInt(localStorage.getItem("customerId"))
          : parseInt(localStorage.getItem("userId")),
      date:
        localStorage.getItem("now") == "true"
          ? ""
          : localStorage.getItem("orderDate"),
      now:
        localStorage.getItem("type") == "cashier"
          ? true
          : localStorage.getItem("now"),
      time:
        localStorage.getItem("now") == "true"
          ? ""
          : localStorage.getItem("orderTime"),
      tableId:
        parseInt(localStorage.getItem("orderType")) == 5
          ? parseInt(localStorage.getItem("selectedTable"))
          : "",
      userOrganizationId: JSON.parse(localStorage.getItem("accountType"))
        ? parseInt(localStorage.getItem("organizationId"))
        : null,
      accountType: localStorage.getItem("type"),
      platform: "agent",
    };
    console.log(data);

    create_order(data, token)
      .then((response) => {
        const orderId = response.data.data.orderId;
        localStorage.setItem("orderId", orderId);

        if (selectedPayment === "shabka") {
          // Proceed with POS payment
          handlePosPayment(payment.totalPrice * 100, orderId);
        } else {
          handleOrderSuccess(response);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history("/");
        } else if (error.response.status === 403) {
          showError(
            "لا توجد إذن لهذا الإجراء",
            "No permission for this action"
          );
        } else {
          getError(error.response.data.error);
        }
      });
  };

  const handlePosPayment = (amount) => {
    if (ws) {
      let finalAmount = parseInt(amount) - parseInt(discAmount);
      ws.send(
        JSON.stringify({
          Command: "SALE",
          Amount: finalAmount.toString(),
          OriginalRRN: "",
          MaskedPan: "",
          PrintFlag: "1",
          AdditionalData: "",
        })
      );
    }
  };

  const handlePosResponse = (message, orderId) => {
    const rrnMatch = message.match(/<RRN>(.*?)<\/RRN>/);
    const rrn = rrnMatch ? rrnMatch[1] : null;
    const terminalMatch = message.match(/<TerminalID>(.*?)<\/TerminalID>/);
    const terminal = terminalMatch ? terminalMatch[1] : null;
    const cardSchemeMatch = message.match(/<CardScheme.*?English="(.*?)"/);
    const cardScheme = cardSchemeMatch ? cardSchemeMatch[1] : null;

    // Save CardScheme in local storage
    if (cardScheme) {
      localStorage.setItem("cardScheme", cardScheme);
    }

    const transactionId = `${terminal}-${rrn}`;

    if (message.includes('<Result Arabic="مقبولة" English="APPROVED"/>')) {
      if (transactionId) {
        acceptOrder(orderId, transactionId);
      } else {
        showError(
          "لم يتم العثور على معرف المعاملة.",
          "Transaction ID not found."
        );
      }
    } else if (
      message.includes('<Result Arabic="مرفوضة" English="DECLINED"/>')
    ) {
      showError("تم رفض الدفع.", "Payment declined.");
    } else {
      const orderid = localStorage.getItem("orderId");
      showError(
        "فشل الدفع. حاول مرة أخرى.",
        "Payment failed. Please try again."
      );

      setTimeout(() => {
        history(`/my-order/${orderid}`);
      }, 2000);
    }
  };

  const connectWebSocket = () => {
    const socket = new WebSocket("ws://localhost:7001/intersoft_integration");
    socket.onopen = () => {
      setWs(socket);
    };
    socket.onmessage = (evt) => {
      console.log("Message received:", evt.data);
      handlePosResponse(evt.data, localStorage.getItem("orderId"));
    };
    socket.onerror = (evt) => {
      console.error("WebSocket error:", evt.message);
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
    };
    socket.onclose = () => {
      setStatus("disconnected");
      showError(
        "حدث خطأ في WebSocket. حاول مرة أخرى.",
        "WebSocket error occurred. Please try again."
      );
      console.log("WebSocket connection closed.");
      setWs(null);
    };
  };

  const acceptOrder = (orderId, transactionId) => {
    const token = window.localStorage.getItem("token");

    const data = {
      transactionId: transactionId,
      cardType: localStorage.getItem("cardScheme"),
      platform: "agent",
    };

    accept_order(data, token, orderId)
      .then((response) => {
        handleOrderSuccess(response);
        // localStorage.removeItem("customerId");
      })
      .catch((error) => {
        showError(
          "حدث خطأ في قبول الطلب. حاول مرة أخرى.",
          "Error accepting order. Please try again."
        );
        console.error("Error accepting order:", error);
      });
  };

  const handleOrderSuccess = (response) => {
    const token = window.localStorage.getItem("token");

    localStorage.removeItem("offer");
    localStorage.removeItem("orderDate");
    localStorage.removeItem("orderTime");
    localStorage.removeItem("tableNum");
    localStorage.removeItem("displayId");
    localStorage.removeItem("orderType");
    localStorage.removeItem("now");
    localStorage.removeItem("orderSource");
    localStorage.removeItem("branchOrderTime");
    // localStorage.removeItem("customerId");
    localStorage.removeItem("customerPhone");

    if (localStorage.getItem("type") !== "cashier") {
      localStorage.removeItem("branchId");
    }
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("orderDistrictId");
    localStorage.removeItem("addressId");
    localStorage.removeItem("externalOrderId");

    localStorage.setItem("orderId", response.data.data.orderId);

    localStorage.removeItem("notes");
    localStorage.removeItem("tableDiscount");
    localStorage.removeItem("tableDiscountType");
    localStorage.removeItem("tableAccount");

    localStorage.removeItem("PaymentMethodDisc");
    localStorage.removeItem("promoCode");

    localStorage.removeItem("notesCheckbox");

    localStorage.setItem("discount", "false");
    localStorage.removeItem("PaymentMethodDiscContd");

    if (localStorage.getItem("cancelOrderId")) {
      cancel_direct(localStorage.getItem("cancelOrderId"), token)
        .then((response) => {
          localStorage.removeItem("editNotes");
          localStorage.removeItem("cancelOrderId");
          localStorage.removeItem("editPaymentMethod");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            history("/");
          }
        });
    }

    history("/order-success");
  };

  //called in createOrder function and handleOffer function
  //handles errors
  function getError(msg) {
    if (msg === "branch not support branch pick up") {
      setErrModal(true);
      setEnMsg("branch not support branch pick up");
      setArMsg("الفرع لا يدعم خدمة توصيل الطالبات");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "branch not support delivery") {
      setErrModal(true);
      setArMsg("الفرع لا يدعم خدمة توصيل المنزل");
      setEnMsg("branch not support delivery");
      setSubmitLoading(false);

      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "empty_wallet") {
      setErrModal(true);
      setArMsg("نأسف ، انتهت صلاحية رصيد المحفظة برجاء اختيار طريقة دفع أخري ");
      setEnMsg(
        "Sorry , wallet credit is expired please select another payment method"
      );

      setSubmitLoading(false);
      setCartChange(!cartChange); //called to rerender useEffect

      setTimeout(function () {
        setErrModal(false);
      }, 3000);
    } else if (msg === "branch not exist") {
      setErrModal(true);
      setArMsg("الفرع خارج نطاق الخدمة");
      setEnMsg("branch not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "branch not active") {
      setErrModal(true);
      setArMsg("الفرع غير متاح حاليا");
      setEnMsg("branch not active");
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
      setSubmitLoading(false);
    } else if (msg === "branch not open") {
      setErrModal(true);
      setArMsg("الفرع مغلق الأن");
      setEnMsg("branch not open");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "meal not exist") {
      setErrModal(true);
      setArMsg("الوجبة غير متاحة حاليا");
      setEnMsg("meal not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "meal not active") {
      setErrModal(true);
      setArMsg("الوجبة غير متاحة حاليا");
      setEnMsg("meal not active");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "product not delivery") {
      setErrModal(true);
      setArMsg("الوجبة غير متاحة للتوصيل");
      setEnMsg("product not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "additional not delivery") {
      setErrModal(true);
      setArMsg("الإضافات غير متاحة للتوصيل");
      setEnMsg("additional not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "extra not exist") {
      setErrModal(true);
      setArMsg("الزيادات غير متاحة");
      setEnMsg("extra not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "extra not belong to product") {
      setErrModal(true);
      setArMsg("الزيادات غير متوفرة مع الوجبة");
      setEnMsg("extra not belong to product");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "The selected code is invalid.1") {
      setErrModal(true);
      setArMsg("الكود غير صحيح");
      setEnMsg("The selected code is invalid.");
      document.getElementById("code").value = "";
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "address not valid") {
      setErrModal(true);
      setArMsg("العنوان غير صحيح");
      setEnMsg("address not valid");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "time not in branch time") {
      setErrModal(true);
      setArMsg("الفرع مغلق في الوقت المحدد");
      setEnMsg("time not in branch time");

      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "branch not support fast delivery") {
      setErrModal(true);
      setArMsg("الفرع لا يوفر التوصيل السريع");
      setEnMsg("branch not support fast delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "branch busy") {
      setErrModal(true);
      setArMsg("الفرع مشغول، تقدر تطلب بعد الزحام.");
      setEnMsg(
        "This branch is currently busy. You can order for later when branch is not busy."
      );

      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else if (msg === "branch not support ordinary delivery") {
      setErrModal(true);
      setArMsg("الفرع لا يوفر التوصيل العادي");
      setEnMsg("branch not support ordinary delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    } else {
      setErrModal(true);
      setArMsg(msg);
      setEnMsg(msg);

      setSubmitLoading(false);
      setTimeout(function () {
        setErrModal(false);
      }, 2000);
    }
  }

  //on searching
  //checks if products exist matching search input value
  //if not (not products are available) is displayed  "setNoProduct(true)"
  function handleSearch(e) {
    SetSearchTerm(e.target.value);

    const products =
      productList &&
      productList.filter((data) => {
        if (e.target.value === "") {
          return data;
        } else if (
          data.enName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.arName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.arDescription
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.enDescription
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return data;
        }
      });
    if (products.length == 0) {
      setNoProduct(true);
    } else {
      setNoProduct(false);
    }
  }

  function handleSearchNotes(e) {
    setNotesSearch(e.target.value);

    const cartNotes =
      basket.cart.notes &&
      basket.cart.notes.filter((data) => {
        if (e.target.value === "") {
          return data;
        } else if (data.toLowerCase().includes(e.target.value.toLowerCase())) {
          return data;
        }
      });
    if (cartNotes.length == 0) {
      setNoNotes(true);
    } else {
      setNoNotes(false);
    }
  }
  function calcWallet() {
    if (localStorage.getItem("discount") == "false") {
      console.log(payment.totalPrice);
      if (payment.totalPrice > payment.walletBalance) {
        setPaymentWalletTotal(payment.walletBalance);

        setTotalAfterWallet(payment.totalPrice - payment.walletBalance);

        setWalletLoad(false);
      } else {
        setPaymentWalletTotal(payment.totalPrice);
        setTotalAfterWallet(0);

        setWalletLoad(false);
      }
    } else {
      if (discTotal > payment.walletBalance) {
        setPaymentWalletTotal(payment.walletBalance);
        setTotalAfterWallet(discTotal - payment.walletBalance);

        setWalletLoad(false);
      } else {
        setPaymentWalletTotal(discTotal);
        setTotalAfterWallet(0);

        setWalletLoad(false);
      }
    }
  }

  function handleDelPromo() {
    setDiscount(false);
    setFreeMealsDisc(false);
    setCashBackDisc(false);
    localStorage.setItem("discount", "false");
    localStorage.removeItem("offer");

    setErrModal2(false);
    setCodeValue("");
    if (selectedPayment == "wallet") {
      calcWallet();
    }
  }

  return (
    <div className="bgCon">
      {modal ? (
        <AddToCart
          setErr={props.setErr}
          setMsg={props.setMsg}
          setStyle={props.setStyle}
          add={add}
          setLoading={setLoading}
          setAdd={setAdd}
          data={productData}
          setModal={setModal}
        />
      ) : null}
      {freeMealsModal ? (
        <FreeMeals
          setSubmitLoading={setSubmitLoading}
          createOrder={createOrder}
          button={showButton}
          setModal={setFreeMealsModal}
          freeMeals={freeMeals}
        />
      ) : null}
      {cashBack ? (
        <CashBack
          setSubmitLoading={setSubmitLoading}
          createOrder={createOrder}
          button={showButton}
          setModal={setCashBack}
        />
      ) : null}
      {discountModal ? (
        <Discount
          setSubmitLoading={setSubmitLoading}
          original={payment.totalPrice}
          currencyAr={basket.cart.currencyAr}
          currencyEn={basket.cart.currencyEn}
          createOrder={createOrder}
          button={showButton}
          total={discTotal}
          discount={discAmount}
          setModal={setDiscountModal}
        />
      ) : null}
      {loading ? (
        <div style={{ height: "80vh" }} className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          {errModal ? (
            <ErrorModal
              setModal={setErrModal}
              msg={i18n.language == "ar" ? arMsg : enMsg}
            />
          ) : null}
          {removeModal ? (
            <RemoveItem handleRemove={handleRemove} setModal={setRemoveModal} />
          ) : null}

          {clearModal ? (
            <CleartCart handleClear={handleClear} setModal={setClearModal} />
          ) : null}

          <div className="processNav">
            <Link to="/customer-orders" className="userData">
              <div>
                <img style={{ cursor: "pointer" }} src={icn1} />

                <p>{t("addOrder.profile")}</p>
              </div>
            </Link>

            <div className="dottedLine"></div>

            <Link to="/add-order" className="userData">
              <div>
                <img style={{ cursor: "pointer" }} src={icn2} alt="icon" />
                <p>{t("addOrder.details")}</p>
              </div>
            </Link>

            <div className="dottedLine"></div>
            <div>
              <img src={icn3} />
              <p>{t("addOrder.menu")}</p>
            </div>

            <div className="dottedLine"></div>
            <div>
              <img src={icn4} />
              <p style={{ color: "white" }}>{t("addOrder.send")}</p>
            </div>
          </div>
          <div
            style={
              i18n.language == "ar"
                ? { paddingRight: "1em", paddingLeft: "1.7em" }
                : { paddingRight: "1.7em", paddingLeft: "1em" }
            }
            className={
              toggleCart ? "container-fluid" : "container-fluid hiddenOf"
            }
          >
            <div className="navigateOrder">
              <a>{t("addOrder.search")}</a>
              {i18n.language == "ar" ? (
                <RiArrowLeftSFill />
              ) : (
                <RiArrowRightSFill />
              )}
              <Link
                to="/customer-orders"
                onClick={() => {
                  localStorage.removeItem("promoCode");
                  localStorage.setItem("discount", "false");
                }}
              >
                {t("addOrder.profile")} ({" "}
                {localStorage.getItem("customerPhone") +
                  " - " +
                  localStorage.getItem("customerName")}{" "}
                {localStorage.getItem("customerAwafi") !== "null"
                  ? `- ${t("addOrder.awafi")}`
                  : ""}{" "}
                ){" "}
              </Link>
              {i18n.language == "ar" ? (
                <RiArrowLeftSFill />
              ) : (
                <RiArrowRightSFill />
              )}
              <Link
                to="/add-order"
                onClick={() => {
                  localStorage.removeItem("promoCode");
                  localStorage.setItem("discount", "false");
                }}
              >
                {t("addOrder.details")}
              </Link>
              {i18n.language == "ar" ? (
                <RiArrowLeftSFill />
              ) : (
                <RiArrowRightSFill />
              )}
              {/*basket?
<a className='userData'>{t("addOrder.menu") + " "} ( {i18n.language=="ar"? basket.cart.branch.arName: basket.cart.branch.enName} )</a>
       :*/}
              <a className="userData">
                {t("addOrder.menu") + " "} ({" "}
                {i18n.language == "ar"
                  ? localStorage.getItem("branchNameAr")
                  : localStorage.getItem("branchNameEn")}{" "}
                )
              </a>
            </div>
            <div className="bgCon">
              <div className="row gy-1 menu">
                <div className="col-lg-2 col-md-3 col-sm-4 ">
                  <div
                    style={{
                      position: "sticky",
                      top: "20px",
                      width: "100%",
                      minWidth: "16vw",
                    }}
                  >
                    <div>
                      <p className="menuTitle">{t("menu.categories")}</p>
                    </div>
                    <div
                      // style={{ overflowX: "auto", maxHeight: "50vh" }}
                      className={
                        i18n.language == "ar"
                          ? "categories"
                          : "categories categoriesEn"
                      }
                    >
                      {menuCategories &&
                        menuCategories.map((data, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setCategoryId(data.id);
                              getProducts(data.id);
                              setMenuLoading(true);
                            }}
                            className={
                              touchsupport
                                ? data.id == categoryId
                                  ? "activeCategory"
                                  : null
                                : data.id == categoryId
                                ? "non-touch activeCategory"
                                : "non-touch"
                            }
                          >
                            <img
                              style={
                                i18n.language == "ar"
                                  ? { marginLeft: "0.5em" }
                                  : { marginRight: "0.5em" }
                              }
                              src={data.image}
                            />
                            <p>
                              {i18n.language == "ar"
                                ? data.arName
                                : data.enName}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-8 col-sm-7 col-11
  meals"
                >
                  <div className="menuSrch">
                    <p className="menuTitle">{t("menu.meals")}</p>

                    <div className="srchPos">
                      <input
                        value={searchTerm}
                        onChange={(e) => handleSearch(e)}
                        placeholder={t("dashboard.search")}
                      />
                      <AiOutlineSearch
                        className={
                          i18n.language == "ar"
                            ? "srchIcn"
                            : "srchIcn srchIcnEn"
                        }
                        size={30}
                      />
                    </div>
                  </div>
                  {menuLoading ? (
                    <div style={{ height: "60vh" }} className="loader">
                      <BeatLoader
                        color={"#E47100"}
                        loading={menuLoading}
                        size={30}
                      />
                    </div>
                  ) : (
                    <div
                      // style={{ overflowX: "auto", maxHeight: "50vh" }}
                      className="container-fluid"
                    >
                      <div className="row gy-4">
                        {productList &&
                          productList
                            .filter((data) => {
                              if (searchTerm === "") {
                                //for filtering products on search
                                return data;
                              } else if (
                                data.enName
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()) ||
                                data.arName
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              ) {
                                return data;
                              }
                            })
                            .map((data, index) => (
                              <div
                                className="col-md-3 col-sm-4 col-6  mealDiv"
                                key={index}
                              >
                                <ImInfo
                                  title={
                                    i18n.language == "ar"
                                      ? data.arDescription
                                      : data.enDescription
                                  }
                                  className="info"
                                  size={30}
                                />
                                <p
                                  onClick={
                                    data.active == 1 || laterPeriod == true
                                      ? () => {
                                          getProduct(data.id);
                                          localStorage.setItem(
                                            "promoCode",
                                            codeRef.current.value
                                          );
                                        }
                                      : null
                                  }
                                  className="mealName"
                                >
                                  {" "}
                                  {i18n.language == "ar"
                                    ? data.arName
                                    : data.enName}{" "}
                                </p>
                                <div
                                  onClick={
                                    data.active == 1 || laterPeriod == true
                                      ? () => {
                                          getProduct(data.id);
                                          localStorage.setItem(
                                            "promoCode",
                                            codeRef.current.value
                                          );
                                        }
                                      : null
                                  }
                                  style={{
                                    backgroundImage: `url(${data.image})`,
                                  }}
                                ></div>
                                <p className="mealPrice">
                                  {data.price}{" "}
                                  {i18n.language == "ar"
                                    ? data.arCurrency
                                    : data.enCurrency}
                                </p>
                                <button
                                  disabled={
                                    data.active !== 1 && laterPeriod == false
                                  }
                                  onClick={() => {
                                    getProduct(data.id);
                                    localStorage.setItem(
                                      "promoCode",
                                      codeRef.current.value
                                    );
                                  }}
                                >
                                  {localStorage.getItem("type") ==
                                  "customerService"
                                    ? t("menu.addToCartForCust")
                                    : t("menu.addToCart")}{" "}
                                  <RiShoppingCartFill size={25} />
                                </button>
                              </div>
                            ))}
                      </div>
                    </div>
                  )}

                  {noProduct ? (
                    <div className="noProd">
                      <p>{t("menu.noProd")}</p>
                    </div>
                  ) : null}
                </div>

                {basket && basket.cart.meals.length > 0 ? (
                  <div
                    className={
                      i18n.language == "ar"
                        ? "col-lg-4 col-1"
                        : "col-lg-4 col-1 carEn"
                    }
                  >
                    <div
                      className="cartActn"
                      onClick={() => setToggleCart(!toggleCart)}
                    >
                      <MdArrowForwardIos size={25} className="carArrow" />

                      <p className="crtTxt">{t("menu.cart")}</p>
                      <button className="cartBtn carIcn">
                        <RiShoppingCartFill size={25} />
                      </button>
                    </div>
                    <div
                      className={
                        toggleCart ? "cart cartOpen" : " cart cartClosed"
                      }
                    >
                      <button
                        className="cartBtn"
                        onClick={() => setToggleCart(!toggleCart)}
                      >
                        <IoClose size={30} />
                      </button>

                      <div className="toggleCart">
                        <div className="cartTop">
                          <p>{t("menu.cart")}</p>
                          <button onClick={() => setClearModal(true)}>
                            {t("menu.clear")}{" "}
                            <RiDeleteBin6Line style={{ color: "#e11857" }} />
                          </button>
                        </div>

                        {basket.cart.meals &&
                          basket.cart.meals.map((data, index) => (
                            <div className="row cartItem" key={index}>
                              <div className="col-4">
                                <div
                                  className="cartItem-Img"
                                  style={{
                                    backgroundImage: `url(${data.image})`,
                                  }}
                                ></div>
                              </div>

                              <div className="col-8">
                                <p className="cartItem-name">
                                  {i18n.language == "ar"
                                    ? data.product.arName
                                    : data.product.enName}
                                </p>
                                <button
                                  onClick={() => {
                                    setDelId(data.id);

                                    setRemoveModal(true);
                                  }}
                                  className="cartItem-delete"
                                >
                                  <RiDeleteBin6Line
                                    style={{ color: "#e11857" }}
                                  />
                                </button>
                                <p className="cartItem-detail">
                                  {i18n.language == "ar"
                                    ? data.size.arName
                                    : data.size.enName}{" "}
                                  -{" "}
                                  {i18n.language == "ar"
                                    ? data.additional.arName
                                    : data.additional.enName}{" "}
                                  -
                                </p>

                                {data.extras &&
                                  data.extras.map((data) => (
                                    <p
                                      style={{ display: "inline" }}
                                      key={data.id}
                                      className="cartItem-detail"
                                    >
                                      {i18n.language == "ar"
                                        ? data.arName
                                        : data.enName}{" "}
                                      -{" "}
                                    </p>
                                  ))}
                                <div className="cartItem-quan">
                                  <button
                                    disabled={
                                      data.quantity >=
                                        data.avalilableQuantity && disableButton
                                    }
                                    onClick={() =>
                                      handleChangeQuantity(
                                        data.id,
                                        data.quantity + 1,
                                        data.quantity,
                                        data.avalilableQuantity
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    {mealId == data.id ? (
                                      <div className="loader quantityLoader">
                                        <BeatLoader
                                          color={"#E47100"}
                                          loading={quanLoading}
                                          size={10}
                                        />
                                      </div>
                                    ) : null}

                                    <input
                                      type={"number"}
                                      id={data.id}
                                      className="quanBtn"
                                      value={data.quantity}
                                      onBlur={(e) =>
                                        handleChangeQuantity(
                                          data.id,
                                          e.target.value,
                                          data.quantity,
                                          data.avalilableQuantity
                                        )
                                      }
                                    />
                                  </div>
                                  <button
                                    onClick={() =>
                                      data.quantity > 1
                                        ? handleChangeQuantity(
                                            data.id,
                                            data.quantity - 1,
                                            data.quantity,
                                            data.avalilableQuantity
                                          )
                                        : null
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                                <p className="cartItem-price">
                                  <span style={{ fontWeight: "600" }}>
                                    {t("menu.price")}
                                  </span>{" "}
                                  {data.price * data.quantity}{" "}
                                  {i18n.language == "ar"
                                    ? basket.cart.currencyAr
                                    : basket.cart.currencyEn}
                                </p>
                              </div>
                            </div>
                          ))}

                        <hr></hr>
                        <p className="cartDivTitle">{t("menu.notes")}</p>

                        <div
                          id="list1"
                          className="dropdown-check-list"
                          tabIndex="100"
                        >
                          <div
                            style={
                              i18n.language == "ar"
                                ? { paddingLeft: "0.5em" }
                                : { paddingRight: "0.5em" }
                            }
                            className="srchNote"
                          >
                            <span onClick={dropDown} className="anchor">
                              {note == "" ? t("profile.choose") : note}
                            </span>
                          </div>

                          <ul className="items">
                            <li style={{ position: "relative" }}>
                              <input
                                type="text"
                                placeholder={t("dashboard.search")}
                                value={notesSearch}
                                onChange={(e) => handleSearchNotes(e)}
                              />
                              <AiOutlineSearch
                                className={
                                  i18n.language == "ar"
                                    ? "srchIcn"
                                    : "srchIcn srchIcnEn"
                                }
                                size={25}
                              />
                            </li>

                            {basket.cart.notes
                              ? basket.cart.notes
                                  .filter((data) => {
                                    if (notesSearch === "") {
                                      //for filtering products on search
                                      return data;
                                    } else if (
                                      data
                                        .toLowerCase()
                                        .includes(notesSearch.toLowerCase())
                                    ) {
                                      return data;
                                    }
                                  })
                                  .map((data, index) => (
                                    <li
                                      key={index}
                                      style={
                                        i18n.language == "ar"
                                          ? { direction: "ltr" }
                                          : null
                                      }
                                    >
                                      <span> {data}</span>
                                      <input
                                        type="checkbox"
                                        className="checkNotes"
                                        value={data}
                                        onChange={(e) => handleNotes(e)}
                                      />
                                    </li>
                                  ))
                              : null}
                            {noNotes ? <li>{t("addOrder.noResult")}</li> : null}
                          </ul>
                        </div>
                        {/*
<select defaultValue={localStorage.getItem("editNotes")? localStorage.getItem("editNotes"):""} id="notes" onChange={(e)=>setNotes(e.target.value)} required={true}>
<option value={""} disabled>{t("profile.choose")}</option>
{basket.cart.notes?  basket.cart.notes.map((data, index) => 

<option value={data} key={index}>{data}</option>

):null}</select>*/}
                        <textarea
                          name="notes"
                          className="noteText"
                          value={noteText}
                          onChange={(e) => {
                            setNoteText(e.target.value);
                            localStorage.setItem("notes", e.target.value);
                          }}
                        />

                        <hr></hr>

                        {basket.cart.typeId == 2 ? (
                          <>
                            <p className="cartDivTitle">{t("menu.delivery")}</p>

                            <div className="paymentType">
                              <input
                                type="radio"
                                name="delivery"
                                id="delivery"
                                value="delivery"
                                checked
                              />

                              <label
                                htmlFor="delivery"
                                style={
                                  i18n.language == "ar"
                                    ? { marginRight: "0.5em" }
                                    : { marginLeft: "0.5em" }
                                }
                              >
                                {t("menu.ordinary")}
                              </label>
                            </div>
                            <hr></hr>
                          </>
                        ) : null}

                        <p className="cartDivTitle">
                          {t("menu.paymentMethod")}
                        </p>

                        {/* {payment.paymentMethods.wallet ? (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="wallet"
                              id="wallet"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "wallet"
                                );
                                calcWallet();
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "wallet") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "wallet") ||
                                selectedPayment == "wallet"
                                  ? true
                                  : false
                              }
                            />

                            <label
                              htmlFor="wallet"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("profile.wallet") +
                                " " +
                                "(" +
                                payment.walletBalance +
                                (i18n.language == "ar"
                                  ? basket.cart.currencyAr
                                  : basket.cart.currencyEn) +
                                ")"}
                            </label>
                          </div>
                        ) : null} */}

                        {payment.totalPrice >= 300 && (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="tamara"
                              id="tamara"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.setItem(
                                  "selectPayment",
                                  e.target.value
                                );
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "tamara"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "tamara") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "tamara") ||
                                selectedPayment == "tamara"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor="tamara"
                              onClick={() => handleLabelClick("tamara")}
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("tamara")}
                            </label>
                          </div>
                        )}

                        {payment.paymentMethods.cash && (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="cash"
                              id="cash"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "cash"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "cash") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "cash") ||
                                selectedPayment == "cash"
                                  ? true
                                  : false
                              }
                            />

                            <label
                              htmlFor="cash"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("menu.cash")}
                            </label>
                          </div>
                        )}

                        {localStorage.getItem("type") === "cashier" && (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="shabka"
                              id="shabka"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "shabka"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "shabka") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "shabka") ||
                                selectedPayment == "shabka"
                                  ? true
                                  : false
                              }
                            />

                            <label
                              htmlFor="cash"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("shabka")}
                            </label>
                          </div>
                        )}

                        {payment.paymentMethods.paymentOnline &&
                        localStorage.getItem("type") !== "cashier" ? (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="tap"
                              id="tap"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "tap"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "tap") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "tap") ||
                                selectedPayment == "tap"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor="tap"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("menu.credit")}
                            </label>
                          </div>
                        ) : null}

                        {payment.paymentMethods.paid ? (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="paid"
                              id="paid"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "paid"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "paid") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "paid") ||
                                selectedPayment == "paid"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor="paid"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("singleOrder.paid")}
                            </label>
                          </div>
                        ) : null}

                        {parseInt(localStorage.getItem("userAwaifiBalance")) >=
                        0 ? (
                          <div className="paymentType">
                            <input
                              type="radio"
                              name="payment"
                              value="awafi"
                              id="awafi"
                              onChange={(e) => {
                                setSelectedPayment(e.target.value);
                                localStorage.removeItem("offer");
                                localStorage.setItem(
                                  "PaymentMethodDisc",
                                  "awafi"
                                );
                              }}
                              defaultChecked={
                                (localStorage.getItem("PaymentMethodDisc") &&
                                  localStorage.getItem("PaymentMethodDisc") ==
                                    "awafi") ||
                                (localStorage.getItem("editPaymentMethod") &&
                                  localStorage.getItem("editPaymentMethod") ==
                                    "awafi") ||
                                selectedPayment == "awafi"
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor="awafi"
                              style={
                                i18n.language == "ar"
                                  ? { marginRight: "0.5em" }
                                  : { marginLeft: "0.5em" }
                              }
                            >
                              {t("profile.awafiWallet")} ({" "}
                              {localStorage.getItem("userAwaifiBalance")}{" "}
                              {i18n.language === "ar"
                                ? parseInt(localStorage.getItem("branchId")) ===
                                  32
                                  ? "دينار"
                                  : "ريال"
                                : parseInt(localStorage.getItem("branchId")) ===
                                  32
                                ? "BHD"
                                : "SAR"}{" "}
                            </label>
                          </div>
                        ) : null}

                        <hr></hr>

                        {((localStorage.getItem("editPaymentMethod") &&
                          localStorage.getItem("editPaymentMethod") ==
                            "wallet") ||
                          (localStorage.getItem("PaymentMethodDisc") &&
                            localStorage.getItem("PaymentMethodDisc") ==
                              "wallet") ||
                          selectedPayment == "wallet") &&
                        (discount
                          ? !(payment.walletBalance >= discTotal)
                          : !(payment.walletBalance >= payment.totalPrice)) ? (
                          <>
                            <p className="cartDivTitle">
                              {t("menu.paymentContMethod")}
                            </p>
                            {payment.paymentMethods.cash ? (
                              <div className="paymentType">
                                <input
                                  type="radio"
                                  name="paymentContd"
                                  value="cash"
                                  id="cashContd"
                                  onChange={(e) => {
                                    setSelectedPaymentCont(e.target.value);
                                    localStorage.setItem(
                                      "PaymentMethodDiscContd",
                                      "cash"
                                    );
                                  }}
                                  defaultChecked={
                                    (localStorage.getItem(
                                      "PaymentMethodDiscContd"
                                    ) &&
                                      localStorage.getItem(
                                        "PaymentMethodDiscContd"
                                      ) == "cash") ||
                                    selectedPaymentCont == "cash"
                                      ? true
                                      : false
                                  }
                                />

                                <label
                                  htmlFor="cashContd"
                                  style={
                                    i18n.language == "ar"
                                      ? { marginRight: "0.5em" }
                                      : { marginLeft: "0.5em" }
                                  }
                                >
                                  {t("menu.cash")}
                                </label>
                              </div>
                            ) : null}
                            {payment.paymentMethods.paymentOnline ? (
                              <div className="paymentType">
                                <input
                                  type="radio"
                                  name="paymentContd"
                                  value="tap"
                                  id="tapContd"
                                  onChange={(e) => {
                                    setSelectedPaymentCont(e.target.value);
                                    localStorage.setItem(
                                      "PaymentMethodDiscContd",
                                      "tap"
                                    );
                                  }}
                                  defaultChecked={
                                    (localStorage.getItem(
                                      "PaymentMethodDiscContd"
                                    ) &&
                                      localStorage.getItem(
                                        "PaymentMethodDiscContd"
                                      ) == "tap") ||
                                    selectedPaymentCont == "tap"
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  htmlFor="tapContd"
                                  style={
                                    i18n.language == "ar"
                                      ? { marginRight: "0.5em" }
                                      : { marginLeft: "0.5em" }
                                  }
                                >
                                  {t("menu.credit")}
                                </label>
                              </div>
                            ) : null}

                            <hr></hr>
                          </>
                        ) : null}

                        {localStorage.getItem("type") !== "cashier" ? (
                          <>
                            <p className="cartDivTitle">
                              {t("menu.promoCode")}
                            </p>
                            <div className="promoCode">
                              <div style={{ position: "relative" }}>
                                <input
                                  style={!discount ? { width: "92%" } : null}
                                  ref={codeRef}
                                  value={codeValue}
                                  onChange={(e) => {
                                    setCodeValue(e.target.value);
                                    localStorage.setItem(
                                      "promoCode",
                                      e.target.value
                                    );
                                  }}
                                  className={codeInvalid ? "invalid" : null}
                                />
                                {(discount || freeMealsDisc || cashBackDisc) &&
                                !(codeValue == null) &&
                                !(codeValue == "") ? (
                                  <>
                                    <button
                                      onClick={handleDelPromo}
                                      className={
                                        i18n.language == "delPromo delPromoEn"
                                          ? ""
                                          : "delPromo"
                                      }
                                    >
                                      x
                                    </button>

                                    <TiTick className="tick" size={28} />
                                  </>
                                ) : null}
                              </div>

                              <button
                                onClick={() => {
                                  handleOffer(false);
                                }}
                              >
                                {t("menu.confirm")}
                              </button>
                            </div>
                            {errModal2 &&
                            !(codeValue == null) &&
                            !(codeValue == "") ? (
                              <span className="err">
                                {i18n.language == "ar" ? arMsg : enMsg}
                              </span>
                            ) : null}

                            <hr></hr>
                          </>
                        ) : null}

                        {localStorage.getItem("type") == "cashier" &&
                        localStorage.getItem("orderType") == 5 ? (
                          <>
                            <div className="tableDisc row gy-3">
                              <div className="col-6">
                                <p className="cartDivTitle">
                                  {t("menu.discAmount")}
                                </p>

                                <input
                                  type="number"
                                  value={
                                    localStorage.getItem("tableDiscount")
                                      ? localStorage.getItem("tableDiscount")
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTableDiscount(e.target.value);
                                    localStorage.setItem(
                                      "tableDiscount",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-6">
                                <p className="cartDivTitle">
                                  {t("menu.discType")}
                                </p>

                                <select
                                  defaultValue={
                                    localStorage.getItem("tableDiscountType")
                                      ? localStorage.getItem(
                                          "tableDiscountType"
                                        )
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTableDiscountType(e.target.value);
                                    localStorage.setItem(
                                      "tableDiscountType",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    {t("profile.choose")}
                                  </option>

                                  <option
                                    value="percentage"
                                    selected={tableDiscountType == "percentage"}
                                  >
                                    {t("menu.discPercent")}
                                  </option>
                                  <option
                                    value="cash"
                                    selected={tableDiscountType == "cash"}
                                  >
                                    {t("menu.fixed")}
                                  </option>
                                </select>
                              </div>
                              <div className="col-12">
                                <p className="cartDivTitle">
                                  {t("menu.discAccount")}
                                </p>

                                <select
                                  defaultValue={
                                    localStorage.getItem("tableAccount")
                                      ? localStorage.getItem("tableAccount")
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTableAccount(e.target.value);
                                    localStorage.setItem(
                                      "tableAccount",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    {t("profile.choose")}
                                  </option>
                                  {payment.paymentAccounts &&
                                    payment.paymentAccounts.map(
                                      (data, index) => (
                                        <option
                                          value={data.id}
                                          selected={
                                            parseInt(tableAccount) == data.id
                                          }
                                        >
                                          {" "}
                                          {data.name}{" "}
                                          <span> ( {data.id} )</span>
                                        </option>
                                      )
                                    )}
                                </select>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ) : null}

                        <div className="cartBreakdown">
                          <p>{t("menu.total")}</p>
                          <p style={{ fontWeight: "300" }}>
                            {payment.originalPrice}{" "}
                            {i18n.language == "ar"
                              ? basket.cart.currencyAr
                              : basket.cart.currencyEn}
                          </p>
                        </div>

                        {payment.insurancePlatesExists ? (
                          <div className="cartBreakdown">
                            <p>{t("menu.insurance")}</p>
                            <p style={{ fontWeight: "300" }}>
                              {payment.insurancePlatesPrice}{" "}
                              {i18n.language == "ar"
                                ? basket.cart.currencyAr
                                : basket.cart.currencyEn}
                            </p>
                          </div>
                        ) : null}
                        <div className="cartBreakdown">
                          <p>
                            {t("singleOrder.tax") + " ( " + payment.tax + "% )"}
                          </p>
                          <p style={{ fontWeight: "300" }}>
                            {payment.taxValue.toFixed(2)}{" "}
                            {i18n.language == "ar"
                              ? basket.cart.currencyAr
                              : basket.cart.currencyEn}
                          </p>
                        </div>

                        {basket.cart.typeId == 2 ? (
                          <>
                            <div className="cartBreakdown">
                              <p>{t("menu.deliveryPrice")}</p>
                              <p style={{ fontWeight: "300" }}>
                                {payment.deliveryPrice +
                                  " " +
                                  (i18n.language == "ar"
                                    ? basket.cart.currencyAr
                                    : basket.cart.currencyEn)}
                              </p>
                            </div>
                          </>
                        ) : null}

                        {selectedPayment == "wallet" && !walletLoad ? (
                          <>
                            {discount ? (
                              <>
                                <div className="cartBreakdown">
                                  <p>{t("menu.disc")}</p>
                                  <p style={{ fontWeight: "300" }}>
                                    {discAmount.toFixed(2)}{" "}
                                    {i18n.language == "ar"
                                      ? basket.cart.currencyAr
                                      : basket.cart.currencyEn}
                                  </p>
                                </div>
                                <div className="cartBreakdown">
                                  <p>{t("menu.Total")}</p>
                                  <p>
                                    {discTotal}{" "}
                                    {i18n.language == "ar"
                                      ? basket.cart.currencyAr
                                      : basket.cart.currencyEn}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div className="cartBreakdown">
                                <p>{t("menu.Total")}</p>

                                <p>
                                  {payment.totalPrice}{" "}
                                  {i18n.language == "ar"
                                    ? basket.cart.currencyAr
                                    : basket.cart.currencyEn}
                                </p>
                              </div>
                            )}

                            <div className="cartBreakdown">
                              <p>{t("menu.walletDisc")}</p>

                              <p>
                                {paymentWalletTotal}{" "}
                                {i18n.language == "ar"
                                  ? basket.cart.currencyAr
                                  : basket.cart.currencyEn}
                              </p>
                            </div>

                            <div
                              style={{ backgroundColor: "rgb(225, 24, 87)" }}
                              className="cartBreakdown"
                            >
                              <p>{t("menu.TotalWallet")}</p>

                              <p>
                                {totalAfterWallet.toFixed(2)}{" "}
                                {i18n.language == "ar"
                                  ? basket.cart.currencyAr
                                  : basket.cart.currencyEn}
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            {discount ? (
                              <>
                                <div className="cartBreakdown">
                                  <p>{t("menu.disc")}</p>
                                  <p style={{ fontWeight: "300" }}>
                                    {discAmount.toFixed(2)}{" "}
                                    {i18n.language == "ar"
                                      ? basket.cart.currencyAr
                                      : basket.cart.currencyEn}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "rgb(225, 24, 87)",
                                  }}
                                  className="cartBreakdown"
                                >
                                  <p>{t("menu.Total")}</p>
                                  <p>
                                    {discTotal}{" "}
                                    {i18n.language == "ar"
                                      ? basket.cart.currencyAr
                                      : basket.cart.currencyEn}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div
                                style={{ backgroundColor: "rgb(225, 24, 87)" }}
                                className="cartBreakdown"
                              >
                                <p>{t("menu.Total")}</p>

                                <p>
                                  {payment.totalPrice}{" "}
                                  {i18n.language == "ar"
                                    ? basket.cart.currencyAr
                                    : basket.cart.currencyEn}
                                </p>
                              </div>
                            )}
                          </>
                        )}

                        {submitLoading ? (
                          <div
                            style={{ height: "5vh", marginTop: "1em" }}
                            className="loader"
                          >
                            <BeatLoader
                              color={"#E47100"}
                              loading={submitLoading}
                              size={20}
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              localStorage.getItem("offer")
                                ? createOrder()
                                : handleOffer(true);
                            }}
                            className="sendOrder"
                          >
                            {t("menu.sendForCust")}
                          </button>
                        )}

                        <hr></hr>

                        <div className="cartTop">
                          <p>{t("addOrder.details")}</p>
                        </div>

                        <div className="cstmrDetails">
                          <p>
                            <span>{t("dashboard.cstmrPhone")} : </span>
                            {localStorage.getItem("customerPhone")}
                          </p>

                          <p>
                            <span>{t("profile.cstmrName")} : </span>
                            {localStorage.getItem("customerName")}
                          </p>

                          <p>
                            <span>{t("singleOrder.order_time")} : </span>{" "}
                            {localStorage.getItem("type") == "cashier"
                              ? t("addOrder.now")
                              : localStorage.getItem("now") == "true"
                              ? t("addOrder.now")
                              : `${t("addOrder.later")}  ${localStorage.getItem(
                                  "orderDate"
                                )} ${localStorage.getItem("orderTime")}`}
                          </p>

                          <p>
                            <span>{t("orders.orderType")} : </span>
                            {localStorage.getItem("orderType") == 1
                              ? t("addOrder.branch")
                              : localStorage.getItem("orderType") == 5
                              ? t("menu.tables")
                              : t("menu.home")}
                          </p>
                          {localStorage.getItem("orderType") == 5 ? (
                            <p>
                              <span>{t("tables.tableNum")} : </span>
                              {localStorage.getItem("displayId") == 0
                                ? t("menu.waiting")
                                : localStorage.getItem("displayId")}
                            </p>
                          ) : null}

                          {JSON.parse(
                            localStorage.getItem("orderSourceObj")
                          ) !== null && (
                            <p>
                              <span>{t("orders.source")} : </span>
                              {
                                JSON.parse(
                                  localStorage.getItem("orderSourceObj")
                                ).name
                              }
                            </p>
                          )}

                          {JSON.parse(localStorage.getItem("orderSource")) !==
                            5 &&
                            JSON.parse(
                              localStorage.getItem("externalOrderId")
                            ) !== null && (
                              <p>
                                <span>{t("menu.external")} </span>
                                {JSON.parse(
                                  localStorage.getItem("externalOrderId")
                                )}
                              </p>
                            )}

                          {localStorage.getItem("orderType") == 2 ? (
                            <p>
                              <span>{t("menu.address")} : </span>
                              {i18n.language == "ar"
                                ? basket.cart.address.arAddress
                                : basket.cart.address.enAddress}
                              <br />
                              {i18n.language == "ar"
                                ? t("profile.branch") +
                                  ": " +
                                  basket.cart.branch.arName
                                : t("profile.branch") +
                                  ": " +
                                  basket.cart.branch.enName}
                            </p>
                          ) : (
                            <>
                              <p>
                                <span>{t("profile.branch")} : </span>
                                {i18n.language == "ar"
                                  ? basket.cart.branch.arName
                                  : basket.cart.branch.enName}
                              </p>
                              <p>
                                <span>{t("addOrder.pickup")} : </span>{" "}
                                {localStorage.getItem("branchOrderTime")}{" "}
                                {t("addOrder.minutes")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}

export default Menu;
