import "../Styles/Login.css";
import background from "../Images/log-in-background.png";
import logo from "../Images/logo.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { login } from "../api";
import * as yup from "yup";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BeatLoader } from "react-spinners";

function Login() {
  const [hidden, setHidden] = useState(false);
  const { t, i18n } = useTranslation();

  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [loading, setLoading] = useState(false);

  const [errorMsgModel, setErrorMsgModel] = useState(false);
  let history = useNavigate();

  const Schema = yup.object().shape({
    name: yup.string().required("validation.name_required"),
    password: yup
      .string()
      .min(6, "validation.at_least")
      .required("validation.password_required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const nameRef = useRef();
  const passRef = useRef();

  //logging in takes username and password
  //if valid user data is stored and user is logged in

  async function onSubmit(event) {
    setLoading(true);
    const data = {
      username: nameRef.current.value,
      password: passRef.current.value,
      pushToken: localStorage.getItem("pushToken")
        ? localStorage.getItem("pushToken")
        : "",
    };

    login(data)
      .then((response) => {
        if (response.data.message === "success") {
          
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("userName", response.data.data.user.name);
          localStorage.setItem("userId", response.data.data.user.id);
          console.log(response.data.data)
          localStorage.removeItem("activeBranch", data);

          localStorage.setItem("userPhone", response.data.data.user.phone);
          localStorage.setItem("userPhoto", response.data.data.user.photo);

          localStorage.setItem("isOnline", response.data.data.user.isOnline);

          localStorage.setItem(
            "isSupervisor",
            response.data.data.user.isSupervisor
          );
          localStorage.setItem("type", response.data.data.user.accountType);
          if (response.data.data.user.accountType == "tableManager") {
            localStorage.setItem(
              "tableBranchAr",
              response.data.data.user.branch.arName
            );
            localStorage.setItem(
              "tableBranchEn",
              response.data.data.user.branch.enName
            );
            localStorage.setItem(
              "tableBranchId",
              response.data.data.user.branch.id
            );
          }
          if (response.data.data.user.accountType == "tableManager") {
            history("/wait-list");
          } else if (
            response.data.data.user.accountType == "productionManger"
          ) {
            history("/quantity");
          } else if (response.data.data.user.accountType == "cashier") {
            localStorage.setItem(
              "branchId",
              JSON.stringify(response.data.data.user.branches[0].id)
            );
            console.log(response.data.data)
            localStorage.setItem(
              "branch",
              JSON.stringify(response.data.data.user.branches[0])
            );
            localStorage.setItem(
              "branchArName",
              JSON.stringify(response.data.data.user.branches[0].arName)
            );
            localStorage.setItem(
              "branchEnName",
              JSON.stringify(response.data.data.user.branches[0].enName)
            );
            localStorage.setItem(
              "types",
              JSON.stringify(response.data.data.user.types)
            );
            localStorage.setItem(
              "customerId",
              JSON.stringify(response.data.data.user.id)
            );
            localStorage.setItem(
              "tableType",
              JSON.stringify(response.data.data.user.tableType.name)
            );
            history("/dashboard");
          } else {
            history("/dashboard");
          }
        } else {
          setErrorMsgModel(true);
          setMsgAr("لم يتم تأكيد هذا الحساب.");
          setMsgEn("This account is not verified.");
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 422) {
          setErrorMsgModel(true);
          setMsgAr("خطأ في اسم المستخدم او كلمة المرور");
          setMsgEn("Invalid username or password");

          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }

  function show() {
    setHidden(!hidden);
    var x = document.getElementById("pass");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <div className="login" style={{ backgroundImage: `url(${background})` }}>
      <div className="alignLogin">
        <form className="loginForm" onSubmit={formik.handleSubmit}>
          {loading ? (
            <div style={{ height: "60vh" }} className="loader">
              <BeatLoader color={"#E47100"} loading={loading} size={30} />
            </div>
          ) : null}

          {!loading ? (
            <>
              <div
                className="loginLogo"
                style={{ backgroundImage: `url(${logo})` }}
              ></div>

              <p>{t("Login.login")}</p>

              <label> {t("Login.userName")}</label>
              <input
                id="name"
                style={{ width: "100%" }}
                value={formik.values.name}
                name="name"
                ref={nameRef}
                required={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  (formik.errors.name && formik.touched.name) || errorMsgModel
                    ? "invalid"
                    : null
                }
              />
              <span>
                {formik.errors.name && formik.touched.name
                  ? t(`${formik.errors.name}`)
                  : null}
              </span>

              <label>{t("Login.pass")}</label>
              <div style={{ position: "relative" }}>
                <input
                  id="pass"
                  style={{ width: "100%" }}
                  type="password"
                  value={formik.values.password}
                  name="password"
                  ref={passRef}
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    (formik.errors.password && formik.touched.password) ||
                    errorMsgModel
                      ? "invalid"
                      : null
                  }
                />
                <span>
                  {formik.errors.password && formik.touched.password
                    ? t(`${formik.errors.password}`)
                    : null}
                </span>
                {hidden ? (
                  <AiOutlineEyeInvisible
                    onClick={show}
                    size={30}
                    className="eye"
                    style={
                      i18n.language == "ar"
                        ? { right: "auto", left: "17px" }
                        : null
                    }
                  />
                ) : (
                  <AiOutlineEye
                    onClick={show}
                    size={30}
                    className="eye"
                    style={
                      i18n.language == "ar"
                        ? { right: "auto", left: "17px" }
                        : null
                    }
                  />
                )}
              </div>
              <div style={{ marginTop: "2em" }}>
                {i18n.language == "ar" ? (
                  <span className="errMsg">{errorMsgModel ? msgAr : null}</span>
                ) : (
                  <span className="errMsg">{errorMsgModel ? msgEn : null}</span>
                )}

                <button type="submit"> {t("Login.log-in")}</button>
              </div>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default Login;
