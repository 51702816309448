import axios from 'axios'


export default axios.create({
    baseURL: 'https://test.alromansiah.com/api/v2/employee/',
    headers: {
        'platform': "agent",

        'Content-type': 'application/json'
    }
}) 

